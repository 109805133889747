import $ from "jquery";
import { breakpoint } from "../breakpoint";

export class MicrositeHeader {
  constructor($el) {
    this.$el = $el;
    this.$primaryNav = this.$el.find(".header__primary-navigation");
    $(this.$primaryNav).css("display", "none");
  }
}
