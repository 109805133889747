import $ from "jquery";

/*
 * Alert
 * Close an alert banner and notify the BE that it's been read via the endpoint in data-endpoint
 */
export class Fabs {
  constructor($el) {
    this.$el = $el;
    this.$leftButtWhole = this.$el.find(".floating-action-button__left");
    this.$fabDrawerOn = true;

    this.drawerOpenedFirstTime = false;
    this.showOnlyFabButton = false;

    this.$fabDrawerOnCheck = this.$el.find(".fab-drawer-on");
    if(this.$fabDrawerOnCheck.length > 0){
      this.$fabDrawerOn = true;
    } else{
      this.$fabDrawerOn = false;
    }
    if(window.innerWidth < 624){
      this.$fabDrawerOn = false;
    }
    this.$leftButt = this.$el.find(".floating-action-button__left .btn--fab");
    this.$leftButtPaddingTop = this.$leftButt.css("padding-top");
    this.$rightButt = this.$el.find(".floating-action-button__right");
    this.$leftButtonIcon = this.$leftButt.find(".btn-content .icon-link__icon")[0];
    this.$textToHideOnScroll = this.$leftButt.find(".btn-content .btn-text")[0];
    this.$textHideWidth = 100;
    this.$savedMarginTopIcon = 0;
    this.$buttonsAll = this.$el.find(".floating-action-bar__button");

    console.log(this.$textToHideOnScroll);
    this.lastScrollTop = 0;
    this.isOpen = false;
    this.textLeftButtonExpanded = true;
    this.drawerOpen = false;
    this.textOpenPercentage = 0;
    this.buttAnimation = null;
    this.$floatingDrawer = this.$el.find(".floating-action-bar__drawer");
    if(this.$floatingDrawer.length == 0){
      console.log("No floating drawer found");
    }
    this.$closeFloatingDrawer = this.$floatingDrawer.find(".floating-action-bar__close");
    $(this.$el).hide();
    this.bindEvents();
    
  }

  bindEvents() {
    let fabs = this;
    let start;

    $(window).on("resize", function () {
      if(fabs.drawerOpen){
        CloseDrawer();
        //$(fabs.$floatingDrawer).css("left", fabs.$leftButtWhole.offset().left);

        // if (window.innerWidth < 624 && fabs.textLeftButtonExpanded) {
        //   //HideLeftButtonText();
        // } else if (window.innerWidth >= 624 && !fabs.textLeftButtonExpanded) {
        // }
        

        if (fabs.isOpen) {
          $(fabs.$floatingDrawer).css("bottom", $(fabs.$el).height() - 1);
        }
        DrawerSizeSameAsButtons();
        LeftDrawerButtonHeightWidthEqual();
      } else {
        LeftDrawerButtonHeightWidthEqual();
      }
    });

    $(fabs.$closeFloatingDrawer).on("click", function(){
      CloseDrawer();
    } );

    $(function(){
      if(fabs.$fabDrawerOn){
        fabs.$textHideWidth = $(fabs.$textToHideOnScroll).width();
       // $(fabs.$floatingDrawer).css("left", fabs.$leftButtWhole.offset().left);

        //HideLeftButtonText();
        DrawerSizeSameAsButtons();

        // OpenDrawer();
        // setTimeout(function(){
        //   console.log(fabs.$buttonsAll);
        //   fabs.$buttonsAll.each(function(){
        //     $(this).css("opacity", 1);
        //   });
        // } , 300);
        } else {
          DrawerSizeSameAsButtons();
          // fabs.$buttonsAll.each(function(){
          //   $(this).css("opacity", 1);
          // });
          // $(fabs.$el).addClass("fab-drop-shadow");
        }
    });

    $(fabs.$leftButt).on("click", function(){
      if(fabs.drawerOpen){
        CloseDrawer();
      } else{
        OpenDrawer();
      }
    });

    function LeftDrawerButtonHeightWidthEqual() {
      if (window.innerWidth < 624) {
        
        $(fabs.$leftButt).css("width", $(fabs.$rightButt).height());
        $(fabs.$leftButt).css("height", $(fabs.$rightButt).height());
      } else {
        
        $(fabs.$leftButt).css("width", "auto");
        $(fabs.$leftButt).css("height", $(fabs.$rightButt).height());
      }
    }

    function DrawerSizeSameAsButtons(){
      
      $(fabs.$floatingDrawer).css("right", "initial");
      $(fabs.$floatingDrawer).css("width", $(fabs.$leftButtWhole).width() + $(fabs.$rightButt).width());
      console.log($(fabs.$leftButtWhole).width() + $(fabs.$rightButt).width());
      LeftDrawerButtonHeightWidthEqual();
    }

    function ShowDrawer() {
      fabs.drawerOpenedFirstTime = true;
      $(fabs.$el).show();
      if (window.innerWidth > 624 && fabs.$floatingDrawer.length > 0) {
        fabs.showOnlyFabButton = false;
        DrawerSizeSameAsButtons();
        OpenDrawer();
        
        $(fabs.$el).css("bottom", "1.3125rem");
        setTimeout(function () {
          fabs.$buttonsAll.each(function (e) {
            $(fabs.$buttonsAll[e]).css("opacity", 1);
            
          });
          //$(fabs.$el).addClass("fab-drop-shadow");
        }, 300);
      } 
      else {
        fabs.showOnlyFabButton = true;
        DrawerSizeSameAsButtons();
        ShowFabsOnly();
        
        // if (!fabs.fabButtonsShowing) {
        //   DrawerSizeSameAsButtons();
        //   $(fabs.$el).css("bottom", -200);
        // }
      }

      // if (!fabs.fabButtonsShowing) {
      //   setTimeout(function () {
      //     fabs.$buttonsAll.each(function (e) {
      //       $(fabs.$buttonsAll[e]).css("opacity", 1);
      //       $(fabs.$el).css("bottom", "1.3125rem");
      //     });
      //     //$(fabs.$el).addClass("fab-drop-shadow");
      //   }, 300);
      //   fabs.fabButtonsShowing = true;
      // }
    }

    function ShowFabsOnly(){
      setTimeout(function () {
        fabs.$buttonsAll.each(function (e) {
          $(fabs.$buttonsAll[e]).css("opacity", 1);
          
        });
        $(fabs.$el).addClass("fab-drop-shadow");
        $(fabs.$el).css("bottom", "1.3125rem");
        
      }, 300);
      DrawerSizeSameAsButtons();
    }

    function OpenDrawer() {
      fabs.drawerOpen = true;
      var x = parseInt($(fabs.$el).css("bottom").split("px")[0], 10);
      console.log(fabs.$el);
      console.log("bottom is:" + x);
      $(fabs.$floatingDrawer).css("bottom", 20);
      $(fabs.$leftButtonIcon).addClass("icon-link-expanded");
      console.log("open drawer");
      $(fabs.$el).removeClass("fab-drop-shadow");
    }

    function CloseDrawer(){
      $(fabs.$floatingDrawer).css("bottom", -400);
      fabs.drawerOpen = false;
      $(fabs.$leftButtonIcon).removeClass("icon-link-expanded");
      $(fabs.$el).addClass("fab-drop-shadow");
    }

    function HideLeftButtonText(){
      clearInterval(fabs.buttAnimation);
      fabs.textOpenPercentage = fabs.$textHideWidth;
      $(fabs.$textToHideOnScroll).addClass("btn-text--hidden");
      fabs.buttAnimation = requestAnimationFrame(ShrinkLeftButtonAnimation);
      $(fabs.$leftButtonIcon).addClass("icon-link__icon--hidden");
      $(fabs.$leftButt).css("height", $(fabs.$rightButt).height());
      $(fabs.$leftButt).css("width", $(fabs.$rightButt).height());
      fabs.textLeftButtonExpanded = false;
      //fabs.isOpen = false;
      //$(fabs.$leftButt).css("padding-top", ($(fabs.$rightButt).height() - $(fabs.$leftButtonIcon).height()) / 2);
      //$(fabs.$leftButt).css("padding-bottom", ($(fabs.$rightButt).height() - $(fabs.$leftButtonIcon).height()) / 2);
    }

    function ShowLeftButtonText(){
      clearInterval(fabs.buttAnimation);
      fabs.textOpenPercentage = 0;
      $(fabs.$textToHideOnScroll).removeClass("btn-text--hidden");
      fabs.buttAnimation = requestAnimationFrame(GrowLeftButtonAnimation);
      $(fabs.$leftButtonIcon).removeClass("icon-link__icon--hidden");
      $(fabs.$leftButt).css("height", $(fabs.$rightButt).height());
      fabs.textLeftButtonExpanded = true;
      //fabs.isOpen = true;
      //$(fabs.$leftButt).css("padding-top", "auto");
      //$(fabs.$leftButt).css("padding-bottom", "auto");
    }

    function GrowLeftButtonAnimation(timestamp){
      if (start === undefined) {
        start = timestamp;
      }
      const elapsed = timestamp - start;

      fabs.textOpenPercentage += 10;
      $(fabs.$textToHideOnScroll).css("width", fabs.textOpenPercentage);
      if(fabs.textOpenPercentage <= fabs.$textHideWidth){
        requestAnimationFrame(GrowLeftButtonAnimation);
      } else if(fabs.textOpenPercentage >= fabs.$textHideWidth){
        clearInterval(fabs.buttAnimation);
        fabs.textOpenPercentage = fabs.$textHideWidth;
        $(fabs.$textToHideOnScroll).css("width", fabs.$textHideWidth);

      }
    }
    function ShrinkLeftButtonAnimation(timestamp){
      if (start === undefined) {
        start = timestamp;
      }
      const elapsed = timestamp - start;

      fabs.textOpenPercentage -= 10;
      $(fabs.$textToHideOnScroll).css("width", fabs.textOpenPercentage);
      if(fabs.textOpenPercentage >= 0){
        requestAnimationFrame(ShrinkLeftButtonAnimation);
      } else if(fabs.textOpenPercentage <= 0){
        clearInterval(fabs.buttAnimation);
        fabs.textOpenPercentage = 0;
      }
    }



    if(window.innerWidth < 624){
      //Always Hide Left Button Text
      HideLeftButtonText();
      //return;
    }

    $(window).on("scroll", function () {
      

      // if(window.innerWidth < 624){
      //   return;
      // }
      var st = $(window).scrollTop();

      if(st > $(window).height() * 0.7 && !fabs.drawerOpenedFirstTime){
        ShowDrawer();
      }
      
      if (st > fabs.lastScrollTop) {
        // downscroll code
        // if(!fabs.isOpen){
        //   //return;
        // } else{

        //   fabs.isOpen = false;
        //   //HideLeftButtonText();
        // }


        // $(fabs.$textToHideOnScroll).addClass("btn-text--hidden");

        // setTimeout(function () {
        //   $(fabs.$textToHideOnScroll).width(0);
        //   $(fabs.$leftButt).css("padding-top", ($(fabs.$rightButt).height() - $(fabs.$leftButtonIcon).height()) / 2);
        // }, 200);
        // setTimeout(function () {
        //   fabs.$savedMarginTopIcon = $(fabs.$leftButtonIcon).css("margin-bottom");
        //   console.log(fabs.$savedMarginTopIcon);
        // }, 400);
        // $(fabs.$leftButtonIcon).addClass("icon-link__icon--hidden");
        // $(fabs.$leftButt).css("height", $(fabs.$rightButt).height());
      } else {
        // upscroll code
        // if(fabs.isOpen){

        // } else{
        //   fabs.isOpen = true;
        // //ShowLeftButtonText();
        // }
        
        // $(fabs.$textToHideOnScroll).width(fabs.$textHideWidth);
        // $(fabs.$leftButtonIcon).css("margin-bottom", fabs.$savedMarginTopIcon);
        // setTimeout(function () {
        //   $(fabs.$textToHideOnScroll).removeClass("btn-text--hidden");
        //   $(fabs.$leftButt).css("padding-top", fabs.$leftButtPaddingTop);
        //   $(fabs.$leftButtonIcon).css("margin-bottom", "auto");

        // }, 200);
        // setTimeout(function () {

        // }, 300);
        // $(fabs.$leftButtonIcon).removeClass("icon-link__icon--hidden");

      }
      fabs.lastScrollTop = st;
    });
  }
}
